import React from 'react';
import { PageContainer, Text } from 'common-components';
import config from 'config';
import { Styles } from 'types';
const Terms = () => {
  const { collectionName, nameOfSingleNft } = config;
  const styles: Styles = {
    container: {
      padding: '130px 0'
    },
    header: {
      margin: '40px 0 10px 0'
    },
    subSection: {
      marginBottom: '15px'
    },
    anchor: {
      fontWeight: 'bold'
    }
  };
  return (
    <PageContainer style={styles.container}>
      <Text style={styles.header} size="medium" bold>
        DISCLAMER
      </Text>
      <Text style={styles.subSection}>
        All investments, including NFTs, is highly speculative in nature and involves substantial risk of loss. We encourage our investors
        to invest very carefully. We also encourage investors to make independent investigations before acting on information that we
        publish. Although the material contained in this website was prepared based on information that Golden Tate Gallery GTG believes to
        be reliable, no representation, warranty or undertaking, stated or implied, is given as to the accuracy of the information contained
        herein, and Golden Tate Gallery GTG expressly disclaims any liability for the accuracy and completeness of information contained in
        its website or its social networks. Past performance is not necessarily indicative of future results. Golden Tate Gallery GTG
        expressly disclaims any liability or loss incurred by any person who acts on the information, ideas or strategies discussed herein
        or in its various social networks. All investments carry significant risk and all investment decisions of an individual remain the
        specific responsibility of that individual. There is no guarantee that systems, indicators, or signals will result in profits or
        that they will not result in a full loss or losses All investors are advised to fully understand all risks associated with any kind
        of investing they choose to do. Don’t enter any investment without fully understanding the worst-case scenarios of that investment.
      </Text>
      <Text style={styles.header} size="medium" bold>
        TERMS & CONDITIONS
      </Text>
      <Text>
        {collectionName} is a collection of digital artworks (NFTs) running on the Elrond network. This website is only an interface
        allowing participants to mint our digital collectibles. Users are entirely responsible for the safety and management of their own
        private Elrond wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the
        Elrond Network smart contract runs on the Elrond network, there is no ability to undo, reverse, or restore any transactions. This
        website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you
        are accepting sole responsibility for any and all transactions involving {nameOfSingleNft} digital collectibles.
      </Text>
      <Text style={styles.header} size="medium" bold>
        OWNERSHIP
      </Text>
      <Text style={styles.subSection}>
        <span style={styles.anchor}>i.</span> You own the NFT. Each ePunk is a unique NFT on the Elrond blockchain. When you purchase an
        NFT, you own the underlying {nameOfSingleNft}, the Art, completely. Ownership of the NFT is mediated entirely by the ESDT Standard
        and the Elrond network: at no point may we seize, freeze, or otherwise modify the ownership of any {nameOfSingleNft}s.
      </Text>
      <Text style={styles.subSection}>
        <span style={styles.anchor}>ii.</span> Personal Use. Subject to your continued compliance with these Terms, Golden Tate Gallery GTG
        grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose
        to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace
        that permits the purchase and sale of your {nameOfSingleNft}s / NFT, provided that the marketplace cryptographically verifies each
        {nameOfSingleNft}s owner’s rights to display the Art for their {nameOfSingleNft}s to ensure that only the actual owner can display
        the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your{' '}
        {nameOfSingleNft}s, provided that the website/application cryptographically verifies each {nameOfSingleNft}s owner’s rights to
        display the Art for their {nameOfSingleNft}s to ensure that only the actual owner can display the Art, and provided that the Art is
        no longer visible once the owner of the {nameOfSingleNft}s leaves the website/application.
      </Text>
      <Text style={styles.subSection}>
        <span style={styles.anchor}>iii.</span> Commercial Use. Subject to your continued compliance with these Terms, Golden Tate Gallery
        GTG grants you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative
        works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the Art to produce and sell
        merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity, nothing in this Section will be deemed
        to restrict you from (i) owning or operating a marketplace that permits the use and sale of {nameOfSingleNft}s generally, provided
        that the marketplace cryptographically verifies each {nameOfSingleNft}s owner’s rights to display the Art for their{' '}
        {nameOfSingleNft}s to ensure that only the actual owner can display the Art; (ii) owning or operating a third party website or
        application that permits the inclusion, involvement, or participation of {nameOfSingleNft}s generally, provided that the third party
        website or application cryptographically verifies each {nameOfSingleNft}s owner’s rights to display the Art for their{' '}
        {nameOfSingleNft}s to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the
        owner of the Purchased {nameOfSingleNft}s leaves the website/application; or (iii) earning revenue from any of the foregoing.
      </Text>
    </PageContainer>
  );
};

export default Terms;
